import React from "react"
import Container from '../Container'
import * as sectionStyle from "../../css/section.module.scss"

const Section = ( {sectionName, bgColor, children} ) => {

	if (!sectionName) return null

	const sectionSlug = sectionName.replace(/\s+/g, '-').toLowerCase()

	const parseBg = () => {
		if (!bgColor) {
			return 'transparent'
		}

		return bgColor
	}

	const bgStyles = {
		backgroundColor: bgColor
	}

	return (

		<section id={sectionSlug} className={`${sectionStyle.section} ${sectionSlug}-section`}
			style={bgStyles}
		>
			<Container>
			{children}
			</Container>

		</section>
	)
}

export default Section
