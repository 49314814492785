import styled from "styled-components"

const Subheading = styled.h6`
  color: black;
  letter-spacing: 4px;
  font-weight: normal;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: .82rem;
`

export default Subheading
