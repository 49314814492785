import React from 'react'

export class SepOne extends React.Component {
	render() {


		return (
			<svg width="142" height="24" viewBox="0 0 142 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M63.1033 2.08081C61.0835 2.04865 59.0755 2.05051 57.0826 2.08504L57.0479 0.0853394C59.0629 0.0504244 61.093 0.0485471 63.1351 0.0810584L63.1033 2.08081ZM75.138 2.67581C73.1274 2.50823 71.122 2.37577 69.1247 2.27717L69.2233 0.279604C71.2428 0.3793 73.2708 0.513247 75.3041 0.682719L75.138 2.67581ZM51.0575 2.29013C49.0325 2.39305 47.0271 2.52943 45.0451 2.6978L44.8758 0.704977C46.8804 0.534693 48.9084 0.396774 50.956 0.292703L51.0575 2.29013ZM87.0978 4.09079C85.1089 3.78417 83.1191 3.51401 81.1314 3.27912L81.3661 1.29294C83.3769 1.53055 85.39 1.80388 87.4025 2.11414L87.0978 4.09079ZM39.0597 3.31086C37.0383 3.55379 35.0464 3.8301 33.0884 4.13807L32.7777 2.16236C34.7599 1.85057 36.7758 1.57094 38.8211 1.32515L39.0597 3.31086ZM98.9222 6.3602C96.9643 5.90766 94.9998 5.4933 93.0314 5.11596L93.408 3.15173C95.4005 3.53371 97.3897 3.95325 99.3726 4.41158L98.9222 6.3602ZM27.1622 5.18624C25.158 5.58113 23.1971 6.00981 21.2843 6.47018L20.8163 4.52571C22.7567 4.05868 24.7448 3.62409 26.7755 3.22397L27.1622 5.18624ZM110.53 9.53092C108.618 8.92408 106.693 8.35736 104.759 7.82964L105.285 5.90018C107.245 6.43492 109.196 7.00933 111.135 7.6246L110.53 9.53092ZM15.4727 8.00867C13.4974 8.58153 11.5863 9.18954 9.74562 9.82991L9.08844 7.94097C10.9637 7.28855 12.9083 6.66998 14.9156 6.08782L15.4727 8.00867ZM121.824 13.6582C119.974 12.8873 118.106 12.1588 116.223 11.4714L116.909 9.59267C118.819 10.2901 120.715 11.0294 122.594 11.812L121.824 13.6582ZM4.15326 11.9655C3.21753 12.3575 2.30586 12.7585 1.41932 13.168L0.580688 11.3523C1.48982 10.9324 2.42347 10.5218 3.38048 10.1208L4.15326 11.9655ZM132.686 18.7971C130.919 17.8533 129.128 16.954 127.318 16.098L128.172 14.2899C130.012 15.1595 131.831 16.0735 133.628 17.033L132.686 18.7971ZM140.462 23.3429C139.618 22.804 138.767 22.2765 137.909 21.7604L138.941 20.0469C139.813 20.572 140.679 21.1087 141.538 21.6572L140.462 23.3429Z" fill="#EAE1D9"/>
			</svg>
		)
	}
}

export class SepTwo extends React.Component {
	render() {


		return (
			<svg width="142" height="24" viewBox="0 0 142 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M141.514 2.3575C140.64 2.88245 139.763 3.3964 138.884 3.8995L137.89 2.16381C138.757 1.66748 139.622 1.16043 140.486 0.642517L141.514 2.3575ZM133.547 6.81128C131.736 7.75133 129.919 8.64722 128.096 9.50019L127.248 7.68868C129.047 6.84731 130.839 5.96357 132.625 5.03627L133.547 6.81128ZM122.532 11.9645C120.65 12.7521 118.763 13.4958 116.872 14.1968L116.177 12.3215C118.042 11.63 119.904 10.8964 121.76 10.1195L122.532 11.9645ZM3.54842 14.2928C2.58231 13.9772 1.62427 13.6558 0.674561 13.3289L1.32543 11.4378C2.26524 11.7613 3.21335 12.0793 4.16947 12.3917L3.54842 14.2928ZM15.2043 17.7017C13.2259 17.1893 11.2735 16.6506 9.34907 16.0878L9.91044 14.1682C11.8152 14.7252 13.7476 15.2585 15.7058 15.7656L15.2043 17.7017ZM111.128 16.1936C109.191 16.8229 107.251 17.4091 105.31 17.9538L104.77 16.0281C106.685 15.4907 108.599 14.9123 110.51 14.2914L111.128 16.1936ZM27.0486 20.3786C25.0464 19.9915 23.0648 19.5754 21.1056 19.1326L21.5465 17.1818C23.4856 17.6201 25.4468 18.0319 27.4283 18.415L27.0486 20.3786ZM99.4222 19.4787C97.4405 19.9499 95.4588 20.3793 93.4783 20.7683L93.0928 18.8058C95.0478 18.4218 97.0038 17.998 98.9595 17.533L99.4222 19.4787ZM39.0396 22.3042C37.0201 22.046 35.0167 21.7564 33.031 21.4374L33.3483 19.4627C35.3131 19.7784 37.2953 20.0649 39.2932 20.3204L39.0396 22.3042ZM87.4917 21.8238C85.4817 22.1382 83.4744 22.4125 81.4711 22.6483L81.2373 20.662C83.2158 20.4291 85.198 20.1582 87.1826 19.8478L87.4917 21.8238ZM51.1336 23.4486C49.1028 23.3244 47.0841 23.1664 45.0792 22.9765L45.2678 20.9854C47.2509 21.1732 49.2474 21.3295 51.2558 21.4524L51.1336 23.4486ZM75.4194 23.246C73.3914 23.4083 71.3689 23.5324 69.3535 23.6201L69.2667 21.6219C71.2582 21.5354 73.2564 21.4127 75.2598 21.2523L75.4194 23.246ZM63.2817 23.7753C61.2461 23.7913 59.2192 23.7711 57.2026 23.7166L57.2567 21.7173C59.2504 21.7712 61.254 21.7911 63.266 21.7754L63.2817 23.7753Z" fill="#EAE1D9"/>
			</svg>

		)
	}
}

export class SepThree extends React.Component {
	render() {


		return (
			<svg width="142" height="24" viewBox="0 0 142 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M63.1032 2.08081C61.0834 2.04865 59.0754 2.05051 57.0825 2.08504L57.0478 0.0853394C59.0627 0.0504244 61.0929 0.0485471 63.135 0.0810584L63.1032 2.08081ZM75.1379 2.67581C73.1273 2.50823 71.1218 2.37577 69.1246 2.27717L69.2232 0.279604C71.2427 0.3793 73.2707 0.513247 75.304 0.682719L75.1379 2.67581ZM51.0574 2.29013C49.0324 2.39305 47.027 2.52943 45.045 2.6978L44.8757 0.704977C46.8803 0.534693 48.9083 0.396774 50.9559 0.292703L51.0574 2.29013ZM87.0976 4.09079C85.1088 3.78417 83.119 3.51401 81.1313 3.27912L81.366 1.29294C83.3767 1.53055 85.3898 1.80388 87.4024 2.11414L87.0976 4.09079ZM39.0596 3.31086C37.0381 3.55379 35.0463 3.8301 33.0883 4.13807L32.7775 2.16236C34.7598 1.85057 36.7757 1.57094 38.821 1.32515L39.0596 3.31086ZM98.9221 6.3602C96.9642 5.90766 94.9996 5.4933 93.0313 5.11596L93.4078 3.15173C95.4004 3.53371 97.3896 3.95325 99.3725 4.41158L98.9221 6.3602ZM27.1621 5.18624C25.1579 5.58113 23.1969 6.00981 21.2842 6.47018L20.8162 4.52571C22.7566 4.05868 24.7447 3.62409 26.7754 3.22397L27.1621 5.18624ZM110.53 9.53092C108.617 8.92408 106.693 8.35736 104.759 7.82964L105.285 5.90018C107.245 6.43492 109.196 7.00933 111.135 7.6246L110.53 9.53092ZM15.4726 8.00867C13.4973 8.58153 11.5861 9.18954 9.7455 9.82991L9.08832 7.94097C10.9636 7.28855 12.9082 6.66998 14.9155 6.08782L15.4726 8.00867ZM121.824 13.6582C119.974 12.8873 118.106 12.1588 116.223 11.4714L116.909 9.59267C118.819 10.2901 120.715 11.0294 122.593 11.812L121.824 13.6582ZM4.15314 11.9655C3.21741 12.3575 2.30574 12.7585 1.4192 13.168L0.580566 11.3523C1.4897 10.9324 2.42335 10.5218 3.38036 10.1208L4.15314 11.9655ZM132.685 18.7971C130.919 17.8533 129.128 16.954 127.318 16.098L128.172 14.2899C130.012 15.1595 131.831 16.0735 133.628 17.033L132.685 18.7971ZM140.462 23.3429C139.618 22.804 138.767 22.2765 137.909 21.7604L138.941 20.0469C139.813 20.572 140.679 21.1087 141.538 21.6572L140.462 23.3429Z" fill="#EAE1D9"/>
			</svg>

		)
	}
}
