import React, {useState, useRef, useEffect} from 'react'
import { Link } from 'gatsby'
import * as buttonStyle from "../../css/btn.module.scss"
import * as formStyle from "../../css/form.module.scss"
import * as contactStyle from "../../css/contact.module.scss"
import axios from "axios"
import PageHeader from '../PageHeader'


const ContactForm = ( {toggleContact, isContact, toggle} ) => {

  const openClass = () => {

		if (toggleContact) {
			return contactStyle.contactActive
		}

		return contactStyle.contactInactive
	}

const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/5c1feb2c-d752-418e-b843-5a8c6ba5d093",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks for reaching out. Our customer service team will be in touch soon.", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };



  return (
    <div className={`bg-variant-default rounded-lg`}>
      <div className="p-12 lg:px-16 lg:py-24 relative">
      <div className="bg-transparent block max-w-xl mx-auto">

      <PageHeader pageTitle="Ready to start building the future?" pageSubtitle="Use the form below to send us a message." align="center" size="text-5xl leading-none font-medium" />

    <form className="form md:-mt-4" onSubmit={handleOnSubmit}>
    <div className="flex flex-row flex-wrap md:flex-nowrap">
      <div className="flex-auto w-full sm:w-2/4 pr-0 sm:pr-2 mb-3">
      <label>
        <span className="text-sm 2xl:text-base font-normal text-black block">First Name</span>
        <input type="text" name="firstName" className={`bg-transparent font-light border border-black w-full p-2 h-12 text-base 2xl:text-lg ${formStyle.formField}`} />
      </label>
      </div>
      <div className="flex-auto w-full sm:w-2/4 pl-0 sm:pl-2 mb-3">
        <span className="text-sm 2xl:text-base font-normal text-black block">Last Name</span>
        <input type="text" name="lastName" className={`bg-transparent font-light border border-black w-full p-2 h-12 text-base 2xl:text-lg ${formStyle.formField}`} />
      </div>
    </div>

    <div className="flex flex-row flex-wrap md:flex-nowrap">
      <div className="flex-auto w-full sm:w-3/5 pr-0 sm:pr-2 mb-3">
      <label>
        <span className="text-sm 2xl:text-base font-normal text-black block">Email</span>
        <input type="email" name="email" className={`bg-transparent font-light border border-black w-full p-2 h-12 text-base 2xl:text-lg ${formStyle.formField}`} />
      </label>
      </div>
      <div className="flex-auto w-full sm:w-2/5 pl-0 sm:pl-2 mb-3">
      <label>
        <span className="text-sm 2xl:text-base font-normal text-black block">Subject</span>
        <select name="subject" id="subject" className={`bg-transparent font-light border border-black w-full p-2 h-12 text-base 2xl:text-lg ${formStyle.formField}`}>
          <option value="general">General inquiry</option>
          <option value="request-quote">Request a quote</option>
          <option value="say-hello">Schedule a demo</option>
          <option value="other">Other</option>
        </select>
      </label>
      </div>
    </div>
    <div className="flex flex-row flex-wrap md:flex-nowrap mb-3">
      <div className="flex-1 mt-2">
      <label htmlFor="message" className="hidden"><span className="text-sm 2xl:text-base font-normal text-black block">Write your message here...</span></label>
        <textarea id="message" name="message" rows="8" className={`w-full text-base 2xl:text-lg text-black bg-transparent border border-black resize-none p-3 font-light ${formStyle.messageField} `} placeholder="Write your message here..." />
      </div>
    </div>

    <input type="text" name="_honey" style={{display: 'none'}} />

    <div className="flex flex-row">
    <div className={`flex-1 p-0`}>
    <input type="submit" className={`w-full h-16 relative text-lg tracking-wide lowercase font-medium font-sans bg-blue-500 text-white hover:bg-blue-700 cursor-pointer`} value="Send Message" disabled={serverState.submitting} />
    </div>
    </div>
	<div className="text-center my-5">
		<p className="text-gray-500 text-sm tracking-wide">Questions? Please refer to our <Link className="underline text-blue-500 hover:text-blue-700" to={`/privacy`}>Privacy Policy</Link>.</p>
	</div>
    {serverState.status && (
      <div className="pt-4 text-center text-sm 2xl:text-base">
              <span className={`${!serverState.status.ok ? "errorMsg" : ""} font-medium tracking-normal`}>
              {serverState.status.msg}
              </span>
      </div>
          )}
    </form>


      </div>
      </div>

    </div>
  )

}

export default ContactForm
