import React from "react"
import styled from "styled-components"
import Container from "./Container"
import Subheading from "./atoms/subheading"
import { media } from "./atoms/media"

import {SepOne, SepTwo, SepThree} from "../svg/Separators"

const SectionSetup = () => {
  return (
    <Container>
      <Intro>
        <Subheading>Setup</Subheading>
        <h2 className="text-xl md:text-3xl lg:text-4xl font-medium leading-tight mb-10 fontPrimary">
          Elevate your business
          <br /> in four simple steps
        </h2>
      </Intro>
      <Setup>
        <Step>
          <Seperator>
            <SepOne />
          </Seperator>
          <Number>1</Number>
          <h4 className="font-medium mb-2 md:text-lg">Identify target markets</h4>
          <p className="text-sm"
          dangerouslySetInnerHTML={{__html: "Research is meaningless without data. We provide access to all the critical resources you need to launch your business in any market."}}
          />
        </Step>
        <Step>
          <Seperator>
            <SepTwo />
          </Seperator>
          <Number>2</Number>
          <h4 className="font-medium mb-2 md:text-lg">Brand consistency</h4>
          <p className="text-sm"
          dangerouslySetInnerHTML={{__html: "Our team will help you ensure your brand or company is well represented, no matter where in the world."}}
          />
        </Step>
        <Step>
          <Seperator>
            <SepThree />
          </Seperator>
          <Number>3</Number>
          <h4 className="font-medium mb-2 md:text-lg">Forge the expansion path</h4>
          <p className="text-sm"
          dangerouslySetInnerHTML={{__html: "Critical insights that afford businesses the confidence to continue expanding while sustaining growth."}}
          />
        </Step>
        <Step>
          <Number>4</Number>
          <h4 className="font-medium mb-2 md:text-lg">Atract new talent</h4>
          <p className="text-sm"
          dangerouslySetInnerHTML={{__html: "Human capital is everything. That's why we emphasize the importance of onboarding the requisite talent to help shepherd your business in this new and exciting arena."}}
          />
        </Step>
      </Setup>
    </Container>
  )
}

export default SectionSetup

const Intro = styled.div`
  text-align: center;
`

const Setup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media ${media.md} {
    padding: 1rem 0;

    grid-template-columns: repeat(4, 1fr);
  }
`

const Seperator = styled.div`
  display: none;
  position: absolute;
  right: -22%;
  top: 5%;
  width: 180px;

  svg {
    width: 100%;
    height: auto;

    path {
      fill: var(--color-cloud);
    }
  }

  @media ${media.lg} {
    display: block;
  }
`

const Step = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;

  &:nth-of-type(2) {
    ${Seperator} {
      top: 25%;
    }
  }
  @media ${media.lg} {
    padding: 1rem;
  }
`
const Number = styled.span`
  align-items: center;
  background-color: #3B82F6;
  border-radius: 100%;
  color: white;
  display: flex;
  font-size: 24px;
  justify-content: center;
  font-family: var(--font-secondary);
  font-weight: 700;
  height: 64px;
  margin-bottom: 0.5rem;
  width: 64px;

  @media ${media.lg} {
    margin-bottom: 1.5rem;
  }
`
