import React from "react"
import PropTypes from "prop-types"
import Btn from "./Btn"

const Banner = ({mainHeading, heading, subheading, subtitle, caption, content, btnText, btnUrl, btnIsLink, location, textAlign, mainOrder, childOrder, children}) => {

	const btnLinkType = () => {
		(btnIsLink === true) ? 'isLink' : ''
	}

	const getLocation = [
		location ? ( location ) : ( 'page' )
	]

	return (

		<>
		<div className={`flex flex-row md:flex-nowrap  justify-center flex-wrap items-center ${getLocation}-banner`}>
			<div className={`w-full md:w-auto md:flex-trueatio ${mainOrder}`} style={{textAlign: textAlign}}>

				{ subtitle &&
					<pre className="m-0 p-0 pl-2 text-xs md:text-sm font-normal my-2 tracking-widest font-sans uppercase"
						style={{letterSpacing: '4px'}}

						>
						{subtitle}
					</pre>
				}

			{ mainHeading &&
				<h1 className="text-5xl md:text-6xl lg:text-7xl font-medium leading-none">
					{mainHeading}
				</h1>
			}

			{ heading &&
				<h2 className="text-5xl md:text-6xl lg:text-7xl font-medium font-secondary leading-none">
					{heading}
				</h2>
			}

			{ subheading &&
				<h3 className="text-4xl md:text-5xl lg:text-h3 mb-8 font-medium font-secondary leading-none">
					{subheading}
				</h3>
			}

			{ caption &&
				<p
				dangerouslySetInnerHTML={{__html: caption}}
				className="mt-5 mb-8 lg:pr-8 text-gray-800 tracking-wider"
				/>


			}

			{ content &&
				<div
				dangerouslySetInnerHTML={{__html: content}}
				className="mt-5 mb-8 lg:pr-8"
				/>


			}

			{ (btnText && btnUrl && btnIsLink !== true) &&

				<Btn linkTo={btnUrl} buttonTitle={btnText} />

			}

			{ (btnText && btnUrl && btnIsLink === true) &&

				<Btn linkTo={btnUrl} buttonTitle={btnText} isLink />

			}

			</div>
			{children &&
				<div className={`w-full md:w-1/2 md:flex-1/2 md:pb-0 pb-12 ${childOrder}`}>

				{children}
				</div>
			}
		</div>
		</>

	)
}

Banner.propTypes = {
  mainHeading: PropTypes.string,
  heading: PropTypes.string,
  caption: PropTypes.string,
  textAlign: PropTypes.string,
  mainOrder: PropTypes.string,
  childOrder: PropTypes.string,
}

Banner.defaultProps = {
  textAlign: `left`,
  mainOrder: `order-2 md:order-1 md:pr-10 lg:pr-12`,
  childOrder: `order-1 md:order-2 md:pl-10 lg:pl-16 md:pb-0 pb-12`,
}

export default Banner
