import React from "react"



const PageHeader = ({pageTitle, pageSubtitle, align, size}) => {

	if (!pageTitle) return null

	const contentAlign = () => {
		if (align) {
			return align
		}

		return 'left'
	}

	const headerSize = () => {

		if (size) {
			return size
		}

		return 'text-5xl lg:text-7xl'
	}

	return (

		<header className={`page-header mb-12 text-${contentAlign()}`}>

			<h1 className={`${headerSize()} font-light`}>{pageTitle}</h1>

			{
				pageSubtitle &&

				<p className="font-light mt-10">{pageSubtitle}</p>
			}

		</header>

	)
}

export default PageHeader
