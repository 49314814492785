import React, { useState } from 'react';
import { Splide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import ClientData from '../data/client-data'

const sliderOptions = {

  type: 'slide',
  rewind: true,
  autoplay: true,
  interval: 4000,
  pagination: false,
  cover: false,
  perPage: 4,
  perMove: 1,
  padding: '2rem',
  arrows: false,
  pagination: false,
  gap: '10rem',
  pauseOnHover: false,
  breakpoints: {
		1024: {
			gap: '5rem',
		},
		787: {
			gap: '2.5rem',
			perPage: 3,
		},
  },
}


const Slider = () => {

	if (typeof window === "undefined") {
	    return <p>Server Render</p>
	 }

	return (
		<div className="flex flex-row">

		<div className="flex-auto w-full">
		<Splide
          onMoved={() => console.log("Slide moved!")}
          options={sliderOptions}
		  className="original__slider mb-10"
        >

		{
	      ClientData.map(client => {

	        return (

			  <li
	            className="splide__slide original__slide relative overflow-visible border-none"
	          >

			  {
				  client.logo &&

				  client.logo
			  }
	          </li>
	        )
	      })
	    }

		</Splide>
		</div>
	</div>
	)
}

export default Slider
