import React from "react"
import * as bgStyle from "../css/backgrounds.module.scss"


const CloudBg = () => {

	return (
		<div className={`${bgStyle.bgOverlay} ${bgStyle.bgCloud}`} />
	)
}

export default CloudBg
