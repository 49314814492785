import React, { useState, useEffect } from 'react';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo'
import ContactForm from '../components/layout/Contact'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Banner from '../components/Banner';
import Section from '../components/layout/Section'
import SectionSetup from '../components/SectionSetup'
import { StaticImage} from "gatsby-plugin-image"
import Fade from 'react-reveal/Fade';
import CloudBg from '../svg/CloudBG'
import Slider from '../components/Slider';

const HomePage = () => {

  const [toggler, setToggler] = useState(false);

  return (
    <Layout>
      <Seo title="Home" />

      <Section sectionName="intro">
      <div className="bg-transparent block" style={{minHeight: "80vh"}}>

	    <Banner mainHeading="Expand your company internationally" subtitle="Streamline your growth" caption="We provide global consulting services to ensure sustainable growth in new and emerging markets—no matter which stage of the expansion process."
	            btnText="learn more"
	            btnIsLink={true}
	            btnUrl="/#setup"
              location="homepage"
	            >
        <Fade right bottom duration={500} distance={`100px`}>
          <StaticImage
              src="../images/stock/checkouts-payments.png"
              quality={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="blurred"
              alt="CLK accepts payments"
            />
        </Fade>
	    </Banner>
      </div>
      </Section>

      <Section sectionName="setup">
        <div style={{paddingTop: '2rem'}} />

          <Banner
            subheading="Available around the clock"
            caption="It doesn't matter where you are—we are there for our clients."
            textAlign="center"
            mainOrder="p-0"
            />
          <SectionSetup />
      </Section>
      <Section sectionName="make-request">
        <div className="py-24 px-20 lg:px-28 text-white flex md:flex-nowrap flex-wrap justify-between bg-gray-700 rounded-lg">
          <h4 className="pr-12 font-medium tracking-wide text-2xl text-white md:w-auto w-full md:mb-0 mb-5">Have any questions? Don't hesitate to reach out.</h4>
          <AnchorLink to="/#contact" className="bg-blue-500 border-2 border-blue-500 text-white hover:bg-transparent hover:text-blue-500 font-sans font-medium tracking-wide button lowercase">Request a Call</AnchorLink>
        </div>
      </Section>
      <Section sectionName="services">

          <Banner
            subheading="Avoid the needless expenses associated with international growth"
            subtitle="Optimize"
            caption="We deliver the peace of mind that comes with knowing that your business is being cared for by a professional who only wants to see you succeed."
            content={`
              <ul>
                <li>Save money through locked-in pricing, lower effective rates, and comprehensive statement analyses</li>
                <li>Simplify your renevue streams—no contracts for low-risk clients</li>
                <li>Our Risk Management Team has you covered, be it handling PCI Compliance or upholding Visa/MC rules and regulations</li>
                <li>Your business is our priority; our team is dedicated to ensuring you're not just happy with our services, but genuinely enthusiastic about it</li>
              </ul>
              `}
            textAlign="left"
            mainOrder="order-2 md:pl-10 lg:pl-12"
            childOrder="order-1 md:pr-10 lg:pr-12"
            >
            <StaticImage
                src="../images/stock/accepting-payments.jpg"
                quality={75}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                alt="CLK accepts payments"
              />
          </Banner>
      </Section>
      <Section sectionName="services-cont">

          <Banner
            subheading="Start accepting payments from clients of all size and scope"
            subtitle="Continue Growing"
            caption="Part of our value as a partner is our payment processing expterise. Managing transactions in a new market can be tricky, but fortunately, CLK has a solution for you."
            content={`
              <ul>
                <li>We offer a myriad of merchant payment solutions in a safe, secure, and reliable manner.</li>
                <li>No one size fits all—CLK understands the vagaries and nuances of the modern economy. Our services depend on the needs of the client.</li>
                  <li>Built-in fraud detection and prevention, so you can focus on growing your revenue streams.</li>
                  <li>Integrate the most common international payment methods, thereby providing your clients with the utmost flexibility.</li>
                  <li>Choose from a number of different packages, each of which tailored to the specific needs of the market.</li>
              </ul>
              `}
            textAlign="left"
            >
            <StaticImage
                src="../images/stock/fast-website.jpg"
                quality={75}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Quick service"
              />
          </Banner>
      </Section>
      <Section sectionName="learn-more">

          <Banner
            subheading="Custom solutions for every vertical"
            subtitle="Expand comfortably—and confidently"
            caption="No two businesses are alike. Let us guide you in this increasingly competitive, digital economy."
            content={`
              <ul>
                <li>To date, our clients range from e-commerce retailers to clothing brands, hospitality companies and more.</li>
                <li>From a cultural perspective, launching a business in a new territory can be tricky. Our Executive Insight team will help you adapt to new markets through detailed, actionable research.</li>
                <li>Services include (but are not limited to): the hiring of staff, locating and securing office space, crafting new business models, general project management—you name it, we do it.</li>
                <li>All with the security of a sophistication of a Payment Gateway that will ensure your accounts receivable is as active as ever.</li>
              </ul>
              `}
            textAlign="left"
            mainOrder="order-2 md:pl-10 lg:pl-12"
            childOrder="order-1 md:pr-10 lg:pr-12"
            >
            <StaticImage
                src="../images/stock/deploying-website.jpg"
                quality={75}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                alt="CLK accepts payments"
              />
          </Banner>
        </Section>

	<Section sectionName="clients">
    <div className="text-center">
      <h3 className="text-2xl md:text-4xl lg:text-5xl font-medium leading-none text-center"
        dangerouslySetInnerHTML={{__html: "Some of our partners"}}
        />
      <p
        className="mt-5"
        dangerouslySetInnerHTML={{__html: "We work with clients of all size to expand their businesses on a global scale."}}
        />
    </div>
			<Slider />
      <div className="-mb-10" />
	</Section>
    <Section sectionName="about">
        <div className="pt-32 border-t border-gray-200" />
        <div className="flex flex-row justify-center">
            <div className="flex-auto pr-12">
              <h2 className="text-2xl md:text-4xl lg:text-5xl font-medium leading-none mb-6">About CLK</h2>
              <ul>
                <li className="uppercase font-normal text-gray-500" style={{fontSize:'0.88rem', letterSpacing: '2px'}}>People-driven, client-focused</li>
                <li
                   className="uppercase font-normal text-gray-500"
                   style={{fontSize:'0.88rem', letterSpacing: '2px'}}
                  dangerouslySetInnerHTML={{__html: "10+ years of experience"}}
                  />
                  <li
                     className="uppercase font-normal text-gray-500"
                     style={{fontSize:'0.88rem', letterSpacing: '2px'}}
                    dangerouslySetInnerHTML={{__html: "Industry-leader in high risk verticals"}}
                    />
              </ul>
            </div>
            <div className="flex-6/12">

                <div>
                  <p>
                    <strong>CLK Project Management</strong> was founded by Christopher Kelly, who aimed to deliver his payment processing services on an international scale.
                  </p>
                  <p>
                    With a vast knowledge and experience in the sundry payment gateway verticals, Chris has helped retailers big and small stay compliant and navigate the ever changing economic landscape. Every day, more and more businesses are entrusting Chris to find fast, secure, and reliable solutions at the best rates.
                  </p>
                </div>
            </div>

        </div>
    </Section>

      <Section sectionName="contact">
          <ContactForm />
      </Section>
      <CloudBg/>
    </Layout>
  )
}

export default HomePage;
