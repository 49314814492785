import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const ClientData = [
	{
		name: 'Cotton Citizen',
		logo:
		<StaticImage
		src={`../images/clients/clk_logos_cotton_citizen.png`}
		alt={`Cotton Citizen`}
		quality={70}
		/>,
	},
	{
		name: 'Glosoft',
		logo:
		<StaticImage
		src={`../images/clients/clk_logos_GLOSOFT.png`}
		alt={`Glosoft`}
		quality={70}
		/>,
	},
	{
		name: 'Conference Direct',
		logo:
		<StaticImage
		src={`../images/clients/logo-conference-direct.png`}
		alt={`Conference Direct`}
		quality={70}
		/>,
	},
	{
		name: 'Bert Rouge Portugal',
		logo:
		<StaticImage
		src={`../images/clients/clk_logos_BERTROUGE.png`}
		alt={`Bert Rouge Portugal`}
		quality={70}
		/>,
	},
	{
		name: 'Silver Stream',
		logo:
		<StaticImage
		src={`../images/clients/clk_logos_SILVERSTREAM.png`}
		alt={`Silver Stream`}
		quality={70}
		/>,
	},
	{
		name: 'Pro Global',
		logo:
		<StaticImage
		src={`../images/clients/logo-proglobal.png`}
		alt={`Pro Global`}
		quality={70}
		/>,
	},
	{
		name: 'Grizzly',
		logo:
		<StaticImage
		src={`../images/clients/logo-grizzly.png`}
		alt={`Grizzly`}
		quality={70}
		/>,
	},
	{
		name: 'Jersey Group',
		logo:
		<StaticImage
		src={`../images/clients/clk_logos_JERSEY_GROUP.png`}
		alt={`Jersey Group`}
		quality={70}
		/>,
	},
	{
		name: 'Blum',
		logo:
		<StaticImage
		src={`../images/clients/logo-blum.png`}
		alt={`Blum`}
		quality={70}
		/>,
	},
	{
		name: 'Harvest Capital',
		logo:
		<StaticImage
		src={`../images/clients/clk_logos_harvest.png`}
		alt={`Harvest Capital`}
		quality={70}
		/>,
	},
]

export default ClientData
